import React from 'react';
import {
  ComponentPreview,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Input } from 'hudl-uniform-ui-components';
import SectionSize from '../partials/_SectionSize';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMicroLabels from '../partials/_SectionMicroLabels';
import SectionMicroPlaceholders from '../partials/_SectionMicroPlaceholders';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import inputPreviewData from '../../../../data/previews/input.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Input"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Input" />

      <PageNavigation>
        <PageNavigationLink>Type</PageNavigationLink>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>State</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Type">
        <Paragraph>
          We currently have eight input types to choose from, all built to
          collect different information.
        </Paragraph>
        <ComponentPreview name="InputType" previewData={inputPreviewData.types}>
          <Input
            type="text"
            label="Name"
            placeholder="e.g. Jane Smith"
            helpText="Your name helps identify you."
          />
        </ComponentPreview>
      </Section>

      <SectionSize text="Input" />

      <Section title="State">
        <Paragraph>
          An input’s state depends on the interaction itself or a submission
          error.
        </Paragraph>
        <ComponentPreview
          name="InputState"
          previewData={inputPreviewData.state}>
          <Input
            type="text"
            label="Name"
            defaultValue="Jane Smith"
            helpText="Please include first and last."
          />
        </ComponentPreview>
      </Section>
      <Section title="Mobile">
        <SectionSubhead>Keyboards</SectionSubhead>
        <Paragraph>
          Entering text on a mobile device can use many different keyboards, but
          it’s important to use the correct one for the input being used. Make
          use of input accessory views for special commands when required.
        </Paragraph>
        <SectionSubhead>Pickers</SectionSubhead>
        <Paragraph>
          Some text inputs may easily make use of available OS pickers to select
          items that don’t need to be input by the user character by character.
          Choose the proper picker to make inputting information as effortless
          as possible.
        </Paragraph>
      </Section>
      <SectionUsage hasLabel hasMobile />

      <Section title="Microcopy">
        <SectionMicroLabels imgName="textinput-label" />
        <SectionMicroPlaceholders imgName="textinput-placeholder" />
        <SectionMicroHelpText imgName="textinput-helptext" />
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
