import React from 'react';
import { DontDo, DontDoItem, Paragraph, SectionSubhead } from 'docComponents';

const SectionMicroHelpText = ({ imgName }) => {
  return (
    <div>
      <SectionSubhead>Help Text</SectionSubhead>
      <Paragraph>
        Help text can provide details not included in the label or placeholder,
        like certain criteria to meet for a valid submission.
      </Paragraph>
      <DontDo gridLayout="2">
        <DontDoItem
          type="dont"
          text="use the help text to provide a sample entry."
          img={imgName + '-dont'}
        />
        <DontDoItem
          type="do"
          text="explain what restrictions might exist in that field."
          img={imgName + '-do'}
        />
      </DontDo>
      <Paragraph>
        In the case of an error, the help text should be updated explaining why
        an error occurred and how it’s fixed.
      </Paragraph>
    </div>
  );
};

export default SectionMicroHelpText;
