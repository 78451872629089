import React from 'react';
import {
  DontDo,
  DontDoItem,
  Link,
  Paragraph,
  SectionSubhead,
} from 'docComponents';

const SectionMicroLabels = ({ imgName }) => {
  return (
    <div>
      <SectionSubhead>Labels</SectionSubhead>
      <Paragraph>
        A label should clearly and concisely describe the field’s purpose with
        no more than a few words.
      </Paragraph>
      <DontDo gridLayout="2">
        <DontDoItem
          type="dont"
          text="make the label a complete sentence telling the user what to do."
          img={imgName + '-dont'}
        />
        <DontDoItem
          type="do"
          text="name exactly what belongs in that field."
          img={imgName + '-do'}
        />
      </DontDo>

      <Paragraph>
        Check{' '}
        <Link href="/words/content-elements/labels-non-label-text">
          UI label guidelines
        </Link>{' '}
        for more detail on case and punctuation.
      </Paragraph>

      <Paragraph>
        For required fields,{' '}
        <strong>place an asterisk at the end of the label</strong>. There’s no
        need to spell out required and extend the length of that label.
      </Paragraph>
    </div>
  );
};

export default SectionMicroLabels;
