import React from 'react';
import {
  DontDo,
  DontDoItem,
  Link,
  Paragraph,
  SectionSubhead,
} from 'docComponents';

const SectionMicroPlaceholders = ({ imgName }) => {
  return (
    <div>
      <SectionSubhead>Placeholders</SectionSubhead>
      <Paragraph>
        Placeholders are suggested but not always necessary. They shouldn’t
        repeat the label outright or insert the label into a CTA (e.g., “Enter
        your email address”).
      </Paragraph>
      <DontDo gridLayout="2">
        <DontDoItem
          type="dont"
          text="repeat the label for the sake of having a placeholder."
          img={imgName + '-dont'}
        />
        <DontDoItem
          type="do"
          text="provide more instruction with a sample entry."
          img={imgName + '-do'}
        />
      </DontDo>
      <Paragraph>
        They can provide an example or preferred format of the content we’re
        looking for, like a date or phone number.
      </Paragraph>
      <Paragraph>
        Placeholders should follow{' '}
        <Link href="/words/content-elements/labels-non-label-text">
          non-label text guidelines
        </Link>
        , but don’t put punctuation on the end.
      </Paragraph>
    </div>
  );
};

export default SectionMicroPlaceholders;
